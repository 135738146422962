export default [
  {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
      path: '',
      redirect: '/dashboard',
      component: () => import('@/layouts/Main.vue'),
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('@views/Dashboard.vue'),
          meta: {
            breadcrumb: [
              {
                text: "Dashboard",
                active: true
              }
            ],
            pageTitle: 'Welcome!',
            authRequired: true,
          }
        },
        {
          path: '/languages',
          name: 'languages',
          component: () => import('@views/groups/GroupList.vue'),
          meta: {
            breadcrumb: [
              {
                text: "Dashboard",
                to: { path: "/dashboard" }
              },
              {
                text: "Languages",
                active: true
              }
            ],
            pageTitle: "Languages List",
            authRequired: true,
          },
        },
        {
          path: '/languages/:groupId(\\d+)',
          name: 'language-show',
          component: () => import('@views/groups/GroupShow.vue'),
          meta: {
            breadcrumb: [
              {
                text: "Languages",
                to: { path: "/groups" }
              },
              {
                text: "Show",
                active: true
              }
            ],
            pageTitle: "Language Group",
            authRequired: true,
          },
        },
        {
          path: '/account',
          name: 'account',
          component: () => import('@views/account/UserEdit.vue'),
          meta: {
            breadcrumb: [
              {
                text: "Dashboard",
                to: { path: "/dashboard" }
              },
              {
                text: "Account",
                active: true
              }
            ],
            pageTitle: 'Profile',
            authRequired: true,
          },
        },
        {
          path: '/notifications',
          name: 'notifications',
          component: () => import('@views/notification/Notifications.vue'),
          meta: {
            breadcrumb: [
              {
                text: "Dashboard",
                to: {path:"dashboard"}
              },
              {
                text: "Notification List",
                active: true
              }
            ],
            pageTitle: "Notifications",
            authRequired: true,
          }
        },
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '',
        component: () => import('@/layouts/FullPage.vue'),
        children: [
          {
              path: '/login',
              name: 'login',
              component: () => import('@/views/pages/Login.vue'),
              meta: {
                requireGuest: true,
              }
          },
          {
              path: '/register',
              name: 'register',
              component: () => import('@/views/pages/Register.vue'),
              meta: {
                requireGuest: true,
              }
          },
          {
              path: '/error-404',
              name: 'error-404',
              component: () => import('@/views/pages/Error404.vue'),
          },
          {
              path: '/error-500',
              name: 'error-500',
              component: () => import('@/views/pages/Error500.vue'),
          },
        ]
    },
  // Redirect to 404 page, if no match found
  {
    path: '*',
    redirect: '/error-404'
  }
]