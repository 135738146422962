// en, de, fr, pt
export default {
	en: {
		Dashboard: 'Dashboard',
		ViewList: 'View List',
		Categories: 'Categories',
		Others: 'Others',
		Users: 'Users',
		Settings: 'Settings',
        JoinAGroup: 'Join a Group',
        CreateGroup: 'Create Group',
        ViewGroups: "View Groups",
        Languages: "Languages",
        Groups: "Groups",


    },
	de: {
		Dashboard: 'Instrumententafel',
		ViewList: 'Listenansicht',
		Categories: 'Categories',
		Others: 'Andere',
		Users: 'Benutzer',
		Settings: 'Settings',
        JoinAGroup: 'Join a Group',
        CreateGroup: 'Create Group',
        ViewGroups: "View Groups",
        Languages: "Languages",
        Groups: "Groups",

		},

	fr: {
		Dashboard: 'Tableau de bord',

		Orders: 'Autre',

		AddNew: 'Add New',
		ViewList: 'Voir la liste',
		Categories: 'Categories',
		Others: 'Others',
		Users: 'Utilisateur',
		Settings: 'Settings',
        JoinAGroup: 'Join a Group',
        CreateGroup: 'Create Group',
        ViewGroups: "View Groups",
        Languages: "Languages",
        Groups: "Groups",

    	},

	pt: {

		Dashboard: 'painel de controle',
		ViewList: 'Exibição de lista',
		Categories: 'Categories',
		Others: 'Outras',
		Users: 'Do utilizador',
		Settings: 'Settings',
        JoinAGroup: 'Join a Group',
        CreateGroup: 'Create Group',
        ViewGroups: "View Groups",
        Languages: "Languages",
        Groups: "Groups",

	}
}
