import axios from "@/plugins/axios.js"

const  actions = {
  fetchGroups({ commit, state}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/groups?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_GROUPS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchGroup({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.get(`/groups/${itemId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_GROUP', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchPosts({ commit, state}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/posts?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_POSTS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },

  fetchEssays({ commit, state}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/essays?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_ESSAYS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

const getters  = {

}


const mutations = {
  ADD_GROUP(state, group) {
    state.pageData.data.unshift(group)
    state.groupIdsBelong.unshift(group.id)
  },
  SET_GROUPS(state, data) {
    state.pageData = data.groups
    state.groupIdsBelong = data.belong
  },
  SET_GROUP(state, group){
    state.group = group
  },
  UPDATE_GROUP(state, group){
    Object.assign(state.group, group)
  },
  DELETE_GROUP(state, itemId){
    const itemIndex = state.pageData.data.findIndex((item) => item.id == itemId)
    state.pageData.data.splice(itemIndex, 1)
  },

  /**************** THIS IS FOR POSTS********************/
  SET_POSTS(state, data){
    state.postData = data
  },
  ADD_POST(state, post){
    state.postData.data.unshift(post)
  },
  UPDATE_POST(state, post){
    const dataIndex = state.postData.data.findIndex((p) => p.id == post.id);
    Object.assign(state.postData.data[dataIndex], post);
  },
  DELETE_POST(state, itemId){
    const itemIndex = state.postData.data.findIndex((item) => item.id == itemId)
    state.postData.data.splice(itemIndex, 1)
  },

  /**************** THIS IS FOR ESSAYS********************/
  SET_ESSAYS(state, data){
    state.essayData = data
  },
  UPDATE_ESSAY(state, essay){
    const dataIndex = state.essayData.data.findIndex((e) => e.id == essay.id);
    Object.assign(state.essayData.data[dataIndex], essay);
  },
}

const state = {
  pageData: {
    data: []
  },
  group: {},
  groupIdsBelong: [],
  postData: {
    data: []
  },
  essayData: {
    data: []
  }
}


export default {
    isRegistered: true,
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}